<template>
  <div class="new-password">
    <form ref="authForm" class="login-form" @submit.prevent="onSubmitForm">
      <h1 class="auth-title">Enter your new password</h1>
      <p class="auth-subtext">Please enter a new password.</p>

      <AppField
        type="password"
        label="Password"
        required
        :isInvalid="$v.password.$error"
        @input="setField($event, 'password')"
      >
        <template v-slot:error>
          <p v-if="!$v.password.required">This field is required</p>
          <p v-if="!$v.password.minLength">
            Password must have at least
            {{ $v.password.$params.minLength.min }} symbols.
          </p>
        </template>
      </AppField>

      <AppButton
        title="Reset Password"
        class="form-btn"
        className="primary"
        type="submit"
        :pending="pending"
      />
    </form>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { authMixin } from "@/mixins/AuthMixin";

export default {
  name: "NewPassword",
  mixins: [authMixin],

  data() {
    return {
      email: "",
      resetToken: "",
      password: "",
    };
  },

  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
  },

  methods: {
    onSubmitForm() {
      const payload = {
        email: this.email,
        token: this.resetToken,
        password: this.password,
      };

      this.sendData(payload, "auth/setNewPassword");
    },
  },

  created() {
    this.email = this.$route.query?.email;
    this.resetToken = this.$route.query?.token;
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
